export function formatStringToHtml(str: string): string {
  return str.replace(/\n/g, '<br>');
}

export function decodeBase64ToString(s: string) {
  let e = {};
  let i;
  let b = 0;
  let c;
  let x;
  let l = 0;
  let a;
  let r = '';
  let w = String.fromCharCode;
  const L = s.length;
  const A = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/';

  for (let i = 0; i < 64; i++) {
    // @ts-ignore
    e[A.charAt(i)]=i;
  }

  for (let x = 0; x < L; x++) {
    // @ts-ignore
    c = e[s.charAt(x)];
    b = (b << 6) + c;
    l += 6;
    while (l >= 8) {
      ((a = (b >>> (l -= 8)) & 0xff) || (x < (L - 2))) && (r += w(a));
    }
  }
  return r;
}

export function createEnsembleUrl(str: string): string {
  const decodedString = decodeBase64ToString(str);
  let ensembleUrl = decodedString.replace('https://safusssample.blob.core.windows.net/cr-search-ensemble', 'https://ens.cgifederal.com');

  return decodeURIComponent(ensembleUrl);
}

export function convertToBoolean(chr: string | number | boolean): boolean {
  const regex=/^\s*(true|1|on)\s*$/i;

  return regex.test(chr.toString());
}

export function convertToOneOrZero(chr: string | boolean): number {
  if (chr === 'true' || chr === true) {
    return 1;
  } else {
    return 0
  }
}
