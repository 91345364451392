<div class="btn-actions-wrapper">
  <button type="button"
          (click)="toggleLike()"
          aria-label="Like this item."
          #tooltip="matTooltip"
          matTooltipPosition="above"
          matTooltip="Like this item">
          <!-- @if (tempVarRandomBoolean) {
              @let nbr = tempMethodRandomNumber();

            <span class="likes">{{nbr}}</span>
            
          } -->
    <mat-icon class="action-icons"
              aria-hidden="false"
              aria-label="thumbs up icon"
              [ngClass]="{'material-symbols-outlined': !like}">
      thumb_up
    </mat-icon>
  </button>

  <button type="button"
          (click)="toggleDislike()"
          aria-label="Item is not for me"
          #tooltip="matTooltip"
          matTooltipPosition="above"
          matTooltip="Item is not for me">
    <mat-icon class="action-icons"
              aria-hidden="false"
              aria-label="thumbs down icon"
              [ngClass]="{'material-symbols-outlined': !dislike}">
      thumb_down
    </mat-icon>
  </button>

  <button type="button"
          (click)="manageBookmark()"
          [attr.aria-label]="ariaLabelBookmark"
          #tooltip="matTooltip"
          matTooltipPosition="above"
          [matTooltip]="ariaLabelBookmark">
    <mat-icon class="action-icons"
              aria-hidden="false"
              [ngClass]="{'material-symbols-outlined': !bookmark}"
              [attr.aria-label]="ariaLabelBookmark">
      @if (bookmark) {
        bookmark_remove_sharp
      } @else {
        bookmark_add_sharp
      }
    </mat-icon>
  </button>

  <a [href]="actionsItem.metadataStoragePath"
     [attr.aria-label]="ariaLabelDownload"
     #tooltip="matTooltip"
     matTooltipPosition="above"
     [matTooltip]="ariaLabelDownload"
     target="_blank"
     class="download-icon" (click)="downloadFile()">
    <mat-icon class="action-icons" aria-hidden="false" aria-label="Download document">
      @if (download) {
        download_done
      } @else {
        download
      }
    </mat-icon>
  </a>

  @if (itemType === resultType.Search) {
    <button type="button" mat-icon-button [matMenuTriggerFor]="menu" aria-label="More actions available">
      <mat-icon>more_vert</mat-icon>
    </button>

    <mat-menu #menu="matMenu" class="more-actions-list">
      <button type="button"
              mat-menu-item
              (click)="shareItem()"
              aria-label="Share document link">
        <mat-icon class="action-icons"
                  aria-hidden="false"
                  aria-label="Share icon">share
        </mat-icon>
        <span>Share document</span>
      </button>
      <button type="button"
              mat-menu-item
              aria-label="Add post"
              (click)="addPost()">
        <mat-icon>post_add</mat-icon>
        <span>Add post</span>
      </button>
    </mat-menu>
  } @else {
    <button type="button"
            mat-menu-item
            (click)="shareItem()"
            aria-label="Share document link"
            #tooltip="matTooltip"
            matTooltipPosition="above"
            matTooltip="Share document link">
      <mat-icon class="action-icons"
                aria-hidden="false"
                aria-label="Share icon">share
      </mat-icon>
    </button>
  }
</div>
