import { inject, Injectable, signal } from '@angular/core';
import { API_URL } from '../../../constants/api.constant';
import { IShareItemRequestBody, IShareLink } from '../../../interfaces/i-share-item';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ShareItemService {
  openShareLinkDialog = signal(false);
  sharedLink = signal<IShareLink>({
    metadataStorageName: '',
    metadataStoragePath: ''
  });

  #http = inject(HttpClient);
  constructor() { }

  shareItem(requestBody: IShareItemRequestBody) {
    const url = `${API_URL}/sendEmail`;

    return this.#http.post(url, requestBody);
  }
}
