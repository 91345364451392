import { Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { BookmarkService } from '../../../../bookmarks/services/bookmark.service';
import { WindowModule } from '@progress/kendo-angular-dialog';
import { ProfileService } from '../../../../user-profile/services/profile.service';
import { IBookmarkRequestBody } from '../../../../../interfaces/i-bookmark';
import { convertToOneOrZero } from '../../../../../utilities/formating';
import { AlertService } from '../../../../../shared/alert/alert.service';
import { SpinnerService } from '../../../../../shared/spinner/spinner.service';
import { AlertEnum } from '../../../../../shared/alert/alert.enum';
import { ShareItemService } from '../../../../share-item/services/share-item.service';
import { ICommentActions } from '../../../../../interfaces/i-comment-actions';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MyPostsService } from '../../../../my-posts/services/my-posts.service';
import {MatTooltipModule} from "@angular/material/tooltip";
import {ResultType} from "../../../../../enums/api.enum";

enum ActionType {
  Bookmark,
  Like,
  Dislike,
  Download,
  Share,
  Post
}

@Component({
  selector: 'comment-actions',
  standalone: true,
  imports: [
    ButtonsModule,
    CommonModule,
    MatIconModule,
    WindowModule,
    MatButtonModule,
    MatMenuModule,
    MatTooltipModule
  ],
  templateUrl: './comment-actions.component.html',
  styleUrl: './comment-actions.component.scss'
})
export class CommentActionsComponent implements OnInit {
  @Input() actionsItem!: ICommentActions;
  @Input() itemType = ResultType.Search;
  @Output() actionsEvent = new EventEmitter<ICommentActions>();

  like = false;
  dislike = false;
  bookmark = true;
  download = false;
  isBookmarkPage = false
  ariaLabelBookmark = 'Add Bookmark';
  ariaLabelDownload = 'Download document';
  resultType = ResultType;
  tempVarRandomBoolean = Math.random() < 0.5;

  #bookmarkService = inject(BookmarkService);
  #myPostsService = inject(MyPostsService);
  #profileService = inject(ProfileService);
  #shareItemService = inject(ShareItemService);
  #alertService = inject(AlertService);
  #spinnerService = inject(SpinnerService);

  tempMethodRandomNumber() {
    return Math.floor(Math.random() * 15);
  }

  ngOnInit(): void {
    this.isBookmarkPage = this.#bookmarkService.isBookmark();
    this.like = this.actionsItem.liked ? this.actionsItem.liked! : false;
    this.bookmark = this.actionsItem.bookmark ? this.actionsItem.bookmark! : false;
    this.dislike = this.actionsItem.disliked ? this.actionsItem.disliked! : false;
    this.download = this.actionsItem.downloaded ? this.actionsItem.downloaded! : false;

    this.#toggleBookmarkLabel();
    this.#manageDownloadFileLabel();
  }

  toggleLike(): void {
    this.like = !this.like;
    this.dislike = false;

    this.#updateResultItemActionForUser(ActionType.Like);
  }

  toggleDislike(): void {
    this.dislike = !this.dislike;
    this.like = false;

    this.#updateResultItemActionForUser(ActionType.Dislike);
  }

  shareItem(): void {
    this.#shareItemService.openShareLinkDialog.set(true);
    this.#shareItemService.sharedLink.set({
      metadataStoragePath: this.actionsItem.metadataStoragePath!,
      metadataStorageName: this.actionsItem.metadataStorageName!
    });
  }

  manageBookmark(): void {
    this.#toggleBookmark();

    this.#updateResultItemActionForUser(ActionType.Bookmark);
  }

  downloadFile(): void {
    this.download = true;
    this.#manageDownloadFileLabel();
  }

  addPost(): void {
    this.#myPostsService.openPostDialog.set(true);
    this.#shareItemService.sharedLink.set({
      metadataStoragePath: this.actionsItem.metadataStoragePath!,
      metadataStorageName: this.actionsItem.metadataStorageName!
    });
  }

  /**
   * Creates the request body for sending the action items to the API.
   * @private
   */
  #updateResultItemActionForUser(type: ActionType) {
    const requestBody: IBookmarkRequestBody = {
      userId: this.#profileService.userProfile().id,
      metadataStoragePath: this.actionsItem.metadataStoragePath!,
      metadataStorageName: this.actionsItem.metadataStorageName,
      score: this.actionsItem.score!.toString(),
      bookmark: convertToOneOrZero(this.bookmark),
      disliked: convertToOneOrZero(this.dislike),
      liked: convertToOneOrZero(this.like),
      dataCard: this.actionsItem.dataCard,
      downloaded: convertToOneOrZero(this.download)
    };

    this.actionsItem = {
      ...this.actionsItem,
      bookmark: this.bookmark,
      disliked: this.dislike,
      liked: this.like,
      downloaded: this.download
    };


    this.#serviceCall(requestBody, type);
  }

  /**
   * Updates the current item (search result or post) with the selected action (ICommentActions).
   * @param requestBody
   * @param type
   * @private
   */
  #serviceCall(requestBody: IBookmarkRequestBody, type: ActionType) {
    this.#spinnerService.show();
    this.#bookmarkService.manageBookmark(requestBody).subscribe({
      next: (resp: any) => {
        if (Array.isArray(resp)) {
          if (Object.hasOwn(resp[0], 'Id')) {
            // Let any parent listening that the action has been taken.
            this.actionsEvent.emit(this.actionsItem);
  
            if (type === ActionType.Bookmark) {
              const message = this.bookmark ? 'added' : 'removed';

              this.#alertService.showAlert({
                message: `Bookmark ${message}.`,
                type: AlertEnum.info,
              });
            }
          } else {
            this.#alertService.showAlert({
              message: 'An error occurred.',
              type: AlertEnum.error,
            });
          }
        }

        this.#spinnerService.hide();
      },
      error: err => {
        this.#alertService.showAlert({
          message: 'An error occurred.',
          type: AlertEnum.error,
        });

        this.#spinnerService.hide();
      }
    });
  }

  /**
   * Toggles whether to bookmark or remove bookmark for the current item (search result or post).
   * @private
   */
  #toggleBookmark(): void {
    this.bookmark = !this.bookmark;
    this.#toggleBookmarkLabel();
  }

  /**
   * Outputs the correct label for the aria-label attribute and title attribute for the bookmark icon.
   * @private
   */
  #toggleBookmarkLabel(): void {
    if (!this.bookmark) {
      this.ariaLabelBookmark = 'Add bookmark';
    } else {
      this.ariaLabelBookmark = 'Remove bookmark';
    }
  }

  /**
   * Outputs the correct label for the aria-label attribute and title attribute for the file download icon.
   * @private
   */
  #manageDownloadFileLabel() {
    this.ariaLabelDownload = this.download ? 'File downloaded' : 'Download document';
  }
}
