import { inject, Injectable, signal } from '@angular/core';
import { IBookmarkRequestBody } from '../../../interfaces/i-bookmark';
import { HttpClient } from '@angular/common/http';
import { API_URL } from '../../../constants/api.constant';

@Injectable({
  providedIn: 'root'
})
export class BookmarkService {
  isBookmark = signal(false);

  #http = inject(HttpClient);

  manageBookmark(requestBody: IBookmarkRequestBody) {
    const url = `${API_URL}/bookmark`;

    return this.#http.post<any>(url, requestBody);
  }
}
